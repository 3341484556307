<template>
  <div>
    <template v-if="bionic">
      <div class="d-flex-center-center">
        <div :id="`${popover.targetId}-customer-switcher`"
             class="bionic-customer-switcher d-flex-center-center no-focus"
             @click.prevent>
          <div class="bionic-customer-switcher-circle cursor-pointer d-flex-center-center">
            {{bionicNavBtnText}}
          </div>
        </div>
      </div>

      <tasty-popover :id="`${popover.popoverId}-customer-switcher`"
                     custom-class="bionic-nav-popover top-0"
                     :target="`${popover.targetId}-customer-switcher`"
                     :delay="popover.delay"
                     :triggers="popover.triggers.customerSwitcher"
                     :boundary-padding="popover.boundaryPadding"
                     placement="right"
                     :resize-helper="true">
        <div v-if="selectOptions.length > 0" class="d-flex">
          <AutoComplete v-model="primeVueInput"
                        :suggestions="filteredCustomerList"
                        optionLabel="text"
                        dropdown
                        placeholder="Select a Customer"
                        force-selection
                        class="flex-grow-1"
                        size="small"
                        overlayClass="zIndex1100"
                        @complete="pvAutoComplete_complete($event)"
                        @blur="blurHandlerPv($event)" />
          <b-btn variant="link" @click="reloadDropdown()"><fluency-icon type="loop" /></b-btn>
        </div>
      </tasty-popover>
    </template>
    <template v-else>
      <div v-if="selectOptions.length > 0">
        <div class="d-flex">
          <basic-mad-lib-input :value="selectedActingAsUser"
                               class="userCustomerSelector"
                               data-qa="userCustomerSelector"
                               :type-ahead-options="selectOptions"
                               placeholder="Select Customer"
                               :retain-value-on-focus="false"
                               text-variant="info font-weight-normal"
                               require-option
                               :pagingSize="100"
                               @input="switchCustomer($event)" />
          <b-btn @click="reloadDropdown()" size="sm" variant="ghost-secondary"><fluency-icon type="loop" /></b-btn>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import BasicMadLibInput from 'core-ui/components/inputs/basicMadLibInput'

export default {
  name: 'userCustomerSelector',
  components: { BasicMadLibInput },
  props: {
    actingAsUser: {
      type: Number
    },
    switchImmediately: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: 'sm'
    },
    dropDown: {
      type: Boolean,
      default: false
    },
    bionic: {
      type: Boolean,
      default: false
    },
    popover: {
      type: Object,
      default: () => { return {} }
    }
  },
  data () {
    return {
      primeVueInput: null,
      filteredCustomerList: null
    }
  },
  mounted () {
    if (this.selectedActingAsUser) {
      this.primeVueInput = this.selectOptions.find(customer => customer.value === this.selectedActingAsUser)
    }
    // this.delayedDebugger()
  },
  watch: {
    primeVueInput: {
      handler (newVal) {
        console.log('=CustomerSwitcher Watcher:=', newVal)
        if (newVal && newVal.hasOwnProperty('value') && newVal?.value !== this.selectedActingAsUser) {
          this.switchCustomerPv(newVal)
        }
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters.user || { email: '', roles: [] }
    },
    selectedActingAsUser () {
      return this.actingAsUser || this.$store.getters.actingAsUser || null
    },
    selectOptions () {
      let options = []
      if (this.user?.allowableCustomers && this.user?.allowableCustomers.length > 0) {
        options = [{ text: 'Select Customer', value: '' }, ...this.user.allowableCustomers.map(c => ({ text: c.name, value: c.customerId }))]
      }
      return options
    },
    bionicNavBtnText () {
      if (this.selectedActingAsUser) {
        const customer = this.selectOptions.find(o => o.value === this.selectedActingAsUser)

        if (customer && customer.text) {
          return customer.text.substr(0, 1)
        }
      }

      return '-'
    }
  },
  methods: {
    pvAutoComplete_complete (event) {
      if (!event.query.trim().length) {
        this.filteredCustomerList = [...this.selectOptions]
      } else {
        this.filteredCustomerList = this.selectOptions.filter(c => {
          return c.text.toLowerCase().indexOf(event.query.toLowerCase()) >= 0 || c.value === ''
        })
      }
    },
    blurHandlerPv (e) {
      if (e?.relatedTarget?.className && ['p-autocomplete-dropdown'].includes(e.relatedTarget.className)) {
        return
      }
      this.emitClosePopover()
    },
    emitClosePopover () {
      setTimeout(() => {
        this.$root.$emit('bv::hide::popover', this.popoverId)
      }, 100)
    },
    // delayedDebugger () {
    //   setTimeout(function () { debugger }, 10000)
    // },
    async reloadDropdown () {
      const response = await this.$res.fetch.user()

      if (response) {
        const newUser = {
          ...this.user,
          allowableCustomers: response.allowableCustomers
        }
        this.$store.commit('user', newUser)
        this.$toast('Customer Dropdown Reloaded', 'success')
      }
    },
    switchCustomerPv (val) {
      console.log('=CustomerSwitcher SwitchTo:=', val, val.value)
      this.switchCustomer(val.value)
    },
    async switchCustomer (val, performSwitch = false, url) {
      this.$emit('change', val)
      if (this.switchImmediately || performSwitch) {
        this.$store.commit('setActingAsUser', val)
        await this.$store.dispatch('clearCustomerSpecificLocalStore')
        if (url) {
          window.location.replace(url)
        } else {
          window.location.reload()
        }
      }
    }
  }
}
</script>

<style scoped>
.bionic-customer-switcher {
  height: 48px;
}
.bionic-customer-switcher-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #596980;
  background-color: #dee5ee;
  font-size: 1.5rem;
  font-weight: 900;
  line-height: 1.5rem;
}
</style>
